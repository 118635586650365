import bulma_notices from './helper/bulma-notices';

bulma_notices();

// add jquery for trigger model menu
$( document ).ready(function(){
  // bulma mobile nav bar trigger helper
  $('.navbar-burger').click(function() {
    $('#navbarMenuHeroA, .navbar-burger').toggleClass('is-active');
  });

  // notification auto hide helper
  $(".notification.is-danger").delay(5000).hide(0);
  $(".notification.is-warning").delay(5000).hide(0);
  $(".notification.is-primary").delay(5000).hide(0);
})
